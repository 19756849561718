function populateCode(item) {
  const $item = document.querySelector(`.js-item--${getItemIndex(item)}`)
  const $html = $item.querySelector('.js-code--html')
  const $css = $item.querySelector('.js-code--css')
  const $example = $item.querySelector('.js-example-content')
  const generalCss = `
    html, body {
      box-sizing: border-box;
      color: #fcfcfc;
      font-family: Consolas, Monaco, 'Ubuntu Mono', monospace;
      height: 100%;
      margin: 0;
      overflow: hidden;
      padding: 0;
      width: 100%;
    }

    *, *:before, *:after {
      box-sizing: inherit;
    }

    .green {
      background-color: #3cc368;
    }

    .blue {
      background-color: #157bad;
    }

    .yellow {
      background-color: #f5a600;
    }

    .red {
      background-color: #ec5840;
    }

    .parent {
      width: 100%;
      height: 100%;
    }

    .section, .box {
      line-height: 1;
      padding: 15px;
    }

    .box {
      border-radius: 6px;
      display: grid;
      font-size: 2rem;
      place-content: center;
    }
  `
  const iframe = document.createElement('iframe');

  $html.innerHTML = encodeHtml(eval(item).html)
  $css.innerHTML = eval(item).css

  iframe.srcdoc = `<style>${generalCss}${eval(item).css}${eval(item).exampleCss}</style> ${eval(item).html}`;
  $example.appendChild(iframe);
}

function getItemIndex(str) {
  return str.split('code')[1]
}

// @see https://stackoverflow.com/a/18750001
function encodeHtml(str) {
  return str.replace(/[\u00A0-\u9999<>&](?!#)/gim, function(i) {
    return `&#${i.charCodeAt(0)};`
  })
}

const code1 = {
  html: `
  <div class="parent">
    <div class="box green" contenteditable>
      :)
    </div>
  </div>
  `,
  css: `
  .parent {
    display: grid;
    place-content: center;
  }
  `,
}

const code2 = {
  html: `
  <div class="parent">
    <div class="section blue" contenteditable>
      Sidebar
    </div>

    <div class="section green" contenteditable>
      Main
    </div>
  </div>
  `,
  css: `
  .parent {
    display: grid;
    grid-template-columns: minmax(150px, 25%) 1fr;
  }
  `,
  exampleCss: `
  .section {
    padding: 1rem;
  }
  `,
}

const code3 = {
  html: `
  <div class="parent">
    <header class="section blue" contenteditable>
      Header
    </header>

    <main class="section green" contenteditable>
      Main
    </main>

    <footer class="section blue" contenteditable>
      Footer
    </footer>
  </div>
  `,
  css: `
  .parent {
    display: grid;
    grid-template-rows: auto 1fr auto;
  }
  `,
}

const code4 = {
  html: `
  <div class="parent">
    <header class="section blue" contenteditable>
      Header
    </header>

    <div class="section yellow left" contenteditable>
      Left Sidebar
    </div>

    <main class="section green" contenteditable>
      Main
    </main>

    <div class="section yellow right" contenteditable>
      Right Sidebar
    </div>

    <footer class="section blue" contenteditable>
      Footer
    </footer>
  </div>
  `,
  css: `
  .parent {
    display: grid;
    grid-template: auto 1fr auto / auto 1fr auto;
  }

  header {
    grid-column: 1 / 4;
  }

  .left {
    grid-column: 1 / 2;
  }

  main {
    grid-column: 2 / 3;
  }

  .right {
    grid-column: 3 / 4;
  }

  footer {
    grid-column: 1 / 4;
  }
  `,
}

const code5 = {
  html: `
  <div class="parent">
    <div class="box green">1</div>
    <div class="box blue">2</div>
    <div class="box yellow">3</div>
    <div class="box red">4</div>
  </div>
  `,
  css: `
  .parent {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns:
      repeat(auto-fit, minmax(150px, 1fr));
  }
  `,
}

const code6 = {
  html: `
  <div class="parent">
    <div class="box green">1</div>
    <div class="box blue">2</div>
    <div class="box yellow">3</div>
    <div class="box red">4</div>
  </div>
  `,
  css: `
  .parent {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns:
      repeat(auto-fill, minmax(150px, 1fr));
  }
  `,
}

const code7 = {
  html: `
  <div class="parent">
    <div class="card green">
      <h1>Lorem ipsum</h1>
      <p>Lorem ipsum dolor sit amet,
      consectetur adipiscing elit.</p>
    </div>
  </div>
  `,
  css: `
  .parent {
    display: grid;
    place-items: center;
  }

  .card {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    width: clamp(200px, 50%, 400px);
  }
  `,
  exampleCss: `
  h1 {
    margin-bottom: 0;
  }
  `,
}

const code8 = {
  html: `
  <div class="parent">
    <div class="section green span-12">Span 12</div>
    <div class="section red span-6">Span 6</div>
    <div class="section blue span-4">Span 4</div>
    <div class="section yellow span-2">Span 2</div>
  </div>
  `,
  css: `
  .parent {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
  }

  .span-12 {
    grid-column: 1 / span 12;
  }

  .span-6 {
    grid-column: 1 / span 6;
  }

  .span-4 {
    grid-column: 4 / span 4;
  }

  .span-2 {
    grid-column: 3 / span 2;
  }
  `,
  exampleCss: `
  .section {
    display: grid;
    place-content: center;
    text-align: center
  }
  `,
}

const code9 = {
  html: `
  <div class="parent">
    <div class="section green">
      Lorem ipsum.
    </div>

    <div class="section blue">
      Lorem ipsum dolor sit amet consectetur.
    </div>
  </div>
  `,
  css: `
  .parent {
    display: flex;
    flex-wrap: wrap;
  }

  .green {
    flex-grow: 1;
  }

  .blue {
    flex-grow: 9999;
    height: 100%;
  }
  `,
}

const code10 = {
  html: `
  <section class="parent">
    <article class="section green">Item 1</article>
    <article class="section blue">Item 2</article>
    <article class="section red">Item 3</article>
  </section>
  `,
  css: `
  .parent {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }

  .parent > * {
    flex: 1 1 150px;
  }
  `,
}

const itemsLength = document.querySelectorAll('.js-item').length
for (let i = 0; i < itemsLength; i++) {
  populateCode(`code${i + 1}`)
}

[...document.querySelectorAll('.js-code-content')].forEach(code => {
  new SimpleBar(code)
})
